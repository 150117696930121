import styled from '@emotion/styled'
import { Separator } from 'app/components/Common/Separator'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Props as ZigZagProps, ZigZag } from './ZigZag'

export interface Props {
  stripes: ZigZagProps[]
}

export const ZigZagList = memo(function ZigZagList({ stripes }: Props) {
  if (stripes.length < 1) {
    return null
  }

  return (
    <Container>
      <Separator />
      {stripes.map((item, index) => (
        <Fade bottom distance="3.75rem" key={index}>
          <ZigZag {...item} />
        </Fade>
      ))}
    </Container>
  )
})

const Container = styled.section`
  margin: 6rem 0;

  @media (max-width: 1023px) {
    margin: 3.75rem 0;
  }
`
